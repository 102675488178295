import { css } from "@emotion/core";
import React from "react";
import { Instagram, Linkedin, Twitter } from '../components/defaults/assets/svg/social';
import { wrapper } from '../components/defaults/styles/elements';
import mq from '../components/defaults/styles/mediaquerys';
import { color } from '../components/defaults/styles/theme';
import Link from "./link";
import StartupLogo from "./main/logo-startup";
import { linkHandler, handleDisplayLinks } from '../templates/peopleProfile';

export const styleInfoFrame = css`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${mq[2]} {
      align-items: flex-start;
  }
`

export const styleInfoEl = css`
  /* max-width: 30rem; */
  flex: 1 0 30rem;
  z-index: 1;
  border: 1px solid ${color.accent_light};
  ${mq[2]} {
    max-width: 30rem;
  }
`

export const ListTitelEl = ({className, children}) => {
    return (
      <p
        className={className}
        css={css`
          font-size: 0.75em;
          letter-spacing: 0.04em;
          margin-bottom: 0;
          color: ${color.main_dark};
          opacity: 0.5;
          margin-bottom: 0.5em;
          font-weight: 500;
        `}
      >{children}</p>
    )
}

export const Profile__Info_Top = ({svg, id}) => {
    
    return (
      <>
        <div
          css={[
            styleInfoEl,
            css`
              width: 100%;
              background: white;
              max-height: 12rem;
              height: 12rem;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              border-bottom: none;
              margin-bottom: -5rem;
              display: flex;
              justify-content: center;
              align-items: center;
            `,
          ]}
        >
          <div
            css={css`
                width: 50%;
                max-width: 13rem;
                height: 50%;
              svg {
                width: 100%;
                height: 100%;
                fill: black;
              }
            `}
          >
            {svg ? svg : id ? <StartupLogo id={id} /> : ''}
          </div>
        </div>
      </>
    )
}

const Profile__Info_Bottom = ({data, id}) => {
    return (
      <div
        css={[
          styleInfoEl,
          css`
            background: white;
            width: 100%;
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 0 1.5em 2.5em;
            position: relative;
            ${mq[2]} {
              padding: 0 2.5em 2.5em;
            }
          `,
        ]}
      >
        <Profile__Main_List_EL title="Company Name" content={data.name} />
        <Profile__Default
          title="Headquarters"
          content={
            data.hq_city
              ? data.hq_country + ", " + data.hq_city
              : data.hq_country
          }
        />
        <Profile__Type_Inline
          css={css`
            padding: 1em 0;
            ${mq[2]} {
              display: none;
            }
          `}
          type={data.type}
        />
        {/* <Profile__Date date={data.start_date} /> */}
        <Profile__Default
          title="Partner of STARTUP AUTOBAHN since"
          content={data.start_date}
        />
        <Profile__Programs program_string={data.program} />
        <Profile__Website url={data.url} />
        <Profile__Social_Card
          twitter={data.twitter}
          linkedin={data.linkedin}
          instagram={data.instagram}
        />

        {/* <div
          css={css`
            position: absolute;
            top: calc(100% + 0.5rem);
            left: 2.5rem;
            font-size: 0.5em;
            opacity: 0.5;
          `}
        >
          <p>{id}</p>
        </div> */}
      </div>
    )
}

export const Profile__Main_List_EL = ({title, content}) => {
    return (
      <div
        css={css`
          width: 100%;
          border-radius: 5px;
          border: 1px solid ${color.accent_light};
          margin-bottom: 1.5em;
          p {
            margin-bottom: 0;
            padding: 0.5em;
          }
        `}
      >
        <p
          css={css`
            background: ${color.accent_light};
            display: block;
            font-size: 0.75em;
            font-weight: 500;
            letter-spacing: 0.04em;
          `}
        >
          {title}
        </p>
        <p css={css`
            font-weight: 500;
        `}>{content}</p>
      </div>
    )
}

// const Profile__Date = ({date}) => {
//     return (
//       <div
//         css={css`
//           padding: 1em 0 .5em;
//         `}
//       >
//         <ListTitelEl>Partner of STARTUP AUTOBAHN since</ListTitelEl>
//         <p
//           css={css`
//             margin-bottom: 0;
//             font-weight: 600;
//             font-size: 1.5em;
//             line-height: 1em;
//           `}
//         >
//           {date}
//         </p>
//       </div>
//     )
// }

export const Profile__Tags = ({content}) => {
  return (
    <p css={css`
        font-size: .75em;
        margin: 0;
        padding: .4em;
        background: ${color.accent_light};
        display: inline-box;
        border-radius: 2px;
        line-height: 1em;
        font-weight: 600;
        letter-spacing: .03em;
        margin-right: .5em;
        margin-bottom: .5em;
    `}>{content}</p>
  )
}


export const Profile__Programs = ({program_string}) => {
    let programs = []
    typeof program_string === "string" ? (
      programs = program_string
        .split(",")
        .map(Function.prototype.call, String.prototype.trim)
        .sort()
    ) : (
      programs = program_string.sort()
    )
      
    return (
      <div
        css={css`
          padding: 1em 0;
        `}
      >
        <ListTitelEl>Programs participated in</ListTitelEl>
        <div css={css`
            line-height: 0;
            margin-top: .5em;
        `}>
          {programs.map(( item, index ) => (
            <Profile__Tags content={item} key={index} />
          ))}
        </div>
      </div>
    )
}

export const Profile__Website = ({url}) => {
    return (
      <div
        css={css`
          padding: 1em 0;
        `}
      >
        <ListTitelEl css={css`
          margin-bottom: 0;
        `}>Official Website</ListTitelEl>
        <Link to={linkHandler(url)} css={css`
            color: ${color.main_dark};
            text-decoration: none;
        `}>{handleDisplayLinks(url)}</Link>
        {/* <Link to={`https://`+ url} css={css`
            color: ${color.main_dark};
            text-decoration: none;
        `}>{url}</Link> */}
      </div>
    )
}

export const Profile__Default = ({title, content}) => {
  return (
    <div
      css={css`
        padding: 1em 0;
      `}
    >
      <ListTitelEl
        css={css`
          margin-bottom: 0;
        `}
      >
        {title}
      </ListTitelEl>
      <p
        css={css`
          color: ${color.main_dark};
          margin: 0;
        `}
      >
        {content}
      </p>
    </div>
  )
}

export const Profile__Horizontal = ({type, twitter, linkedin, instagram, startup}) => {
    return (
      <div
        css={[
          wrapper,
          css`
            display: none;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            padding: 0 1rem 0 34rem;
            ${mq[2]} {
              display: flex;
            }
          `,
        ]}
      >
        <Profile__Type type={type} startup={startup} />
        <Profile__Social twitter={twitter} linkedin={linkedin} instagram={instagram}/>
      </div>
    )
}

export const Profile__Social_Card = ({twitter, linkedin, instagram }) => {
  return (
    <div css={css`
      margin: 1em 0;
      ${mq[2]} {
        display: none;
      }
    `}>
      <ListTitelEl>Social Media</ListTitelEl>
      <Profile__Social css={css`
        margin-top: .75em;
        a {
          margin-left: 0;
          margin-right: 2em;
        }
      `} twitter={twitter} linkedin={linkedin} instagram={instagram} />
    </div>
  )
}

const Profile__Type = ({className, type, startup}) => {
  return(
    <div className={className}>
      <p
        css={css`
        background: ${color.accent_light};
        margin-bottom: 0;
        padding: 0.45rem 1em;
        display: inline-block;
        line-height: 1em;
        font-size: 0.85em;
        font-weight: 600;
        border-radius: 5px;
        letter-spacing: 0.04em;
      `}
      >
        {type} {startup ? "Startup" : "Partner" }
      </p>
    </div>
  )
}

export const Profile__Type_Inline = ({className, type, startup, title}) => {
  let content = ""
  if (startup) {
    content = type + " Vertical"
  } else (
    content = type + ` Partner`
  )

  return (
    <div className={className}>
      <ListTitelEl>{title ? title : "Partnership Type"}</ListTitelEl>
      <Profile__Tags content={content} />
    </div>
  )
}

const Profile__Social = ({className, twitter, linkedin, instagram}) => {
  return (
    <div className={className}
      css={css`
        display: flex;
        align-items: center;
        line-height: 1em;
        a {
          height: 0.85rem;
          display: block;
          margin-left: 1em;
          opacity: 0.2;
          &[data-active] {
            opacity: 1;
          }
          svg {
            fill: ${color.main_dark};
            height: 100%;
            width: auto;
          }
        }
      `}
    >
      <Link to={linkedin} data-active={linkedin ? true : undefined}>
        <Linkedin />
      </Link>
      <Link to={twitter} data-active={twitter ? true : undefined}>
        <Twitter />
      </Link>
      <Link to={instagram} data-active={instagram ? true : undefined}>
        <Instagram />
      </Link>
    </div>
  )
}

export const Profile__Header = ({data, svg, id}) => {

    return (
      <>
        <section
          css={css`
            background: ${color.main_dark};
            width: 100%;
            min-height: 25rem;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: ${color.accent_light};
            }
        `}
        >
          <div css={[wrapper, styleInfoFrame, css``]}>
            <Profile__Info_Top svg={svg} />
          </div>

          <div
            css={css`
              height: 5rem;
              width: 100%;
              background: white;
            `}
          >
            <Profile__Horizontal
              type={data.type}
              twitter={data.twitter}
              linkedin={data.linkedin}
              instagram={data.instagram}
            />
          </div>
        </section>

        <div
          css={css`
            background: ${color.main_light};
            width: 100%;
            padding-bottom: 5em;
          `}
        >
          <div
            css={[
              wrapper,
              styleInfoFrame,
              css`
                flex-direction: column;
                ${mq[2]} {
                  flex-direction: row;
                }
              `,
            ]}
          >
            <Profile__Info_Bottom data={data} id={id} />
            {data.description ? (
            <div
              css={css`
                max-width: 45rem;
                padding: 3rem 0 0;
                align-self: flex-start;
                ${mq[2]} {
                  padding-left: 3rem;
                  padding-right: 0;
                  padding-top: 3rem;
                }
              `}
            >
              {" "}
              <ListTitelEl>Official Statement by {data.name}</ListTitelEl>
              <div
                css={css`
                  p {
                    margin: 0;
                    padding-bottom: 1em;
                    font-size: 0.85em;
                    line-height: 1.8em;
                    ${mq[1]} {
                      font-size: 0.95em;
                    }
                  }c
                `}
                dangerouslySetInnerHTML={{
                  __html: data.description.childMarkdownRemark.html,
                }}
              ></div>
            </div>
            ) : ''}
          </div>
        </div>
      </>
    )
};