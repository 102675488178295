import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout"
import { Profile__Header} from "../components/profile";
import { TitleHeader, ArrowLink, wrapper } from '../components/defaults/styles/elements';
import { color } from '../components/defaults/styles/theme';
import { css } from '@emotion/core';
import ProfileUsers from '../components/main/profile-users';
import Star from '../components/defaults/assets/svg/star.svg';
import Award from '../components/defaults/assets/svg/award.svg';
import Play from '../components/defaults/assets/svg/play.svg';
import Video from '../components/defaults/assets/svg/video.svg';
import { People } from '../components/defaults/assets/svg/mobilenav';
import ProfileYoutube from '../components/main/profile-youtube';
import PressFooter from "../components/pressfooter"
import RearviewInline, { ifRearview } from '../components/main/rearviewInline';

import {
  Adac,
  Agc,
  Arena2036,
  Basf,
  Bleistahl,
  Bosch,
  Bp,
  Covea,
  Daimler,
  Dpdhl,
  Dxc,
  Eberspaecher,
  Faurecia,
  Hyundai,
  Jardines,
  Linde,
  Motherson,
  Murata,
  Pnp,
  Porsche,
  Posteitaliane,
  Rrps,
  Sekisui,
  Tsystems,
  Tuev,
  Unistuttgart,
  Wabco,
  Webasto,
  Wieland,
  Yanfeng,
  Zfwabco,
} from "../components/defaults/assets/logos/partnerlogos/index"


const PartnerIcons = {
  ARENA2036: Arena2036,
  Daimler: Daimler,
  "Plug and Play": Pnp,
  "Universität Stuttgart": Unistuttgart,
  Porsche: Porsche,
  BASF: Basf,
  Bosch: Bosch,
  DPDHL: Dpdhl,
  DXC: Dxc,
  Motherson: Motherson,
  "Rolls-Royce Power Systems": Rrps,
  "T-Systems": Tsystems,
  Wabco: Wabco,
  Webasto: Webasto,
  "ZF Wabco": Zfwabco,
  ADAC: Adac,
  AGC: Agc,
  BP: Bp,
  "Bleistahl Produktions GmbH & Co. KG": Bleistahl,
  Covéa: Covea,
  Eberspächer: Eberspaecher,
  Faurecia: Faurecia,
  Hyundai: Hyundai,
  "Jardine Matheson": Jardines,
  Linde: Linde,
  Murata: Murata,
  "Poste Italiane": Posteitaliane,
  "Sekisui s-lec": Sekisui,
  "TÜV Rheinland": Tuev,
  "Wieland Group": Wieland,
  "Yanfeng Automotive Interiors": Yanfeng,
}


const PartnerTemplate = ({ pageContext, data }) => {
    const { safe_url } = pageContext
    
    let Icon = null
    if (PartnerIcons[data.airtable.data.name] !== undefined) {
      Icon = PartnerIcons[data.airtable.data.name]
    }

    let champions = []
    let executives = []
    let attendee = []

    // remove all !not champions/ops from users array
    if (data.airtable.data.users) {
      const el_to_include = ["STARTUP AUTOBAHN Champion", "Organizer"]
      champions = data.airtable.data.users.filter(
        ({ data }) =>
          el_to_include.some(el => data.group.includes(el)) &&
          data.include === true && data.consent === true
      )

      executives = data.airtable.data.users.filter(
        ({ data }) =>
          data.group.includes("Partner Executive Representative") &&
          data.include === true &&
          data.consent === true
      )
      
      attendee = data.airtable.data.users.filter(
        ({ data }) =>
          data.group.includes("Partner Business Unit Employee") &&
          data.include === true &&
          data.consent === true
      )
    }
  
    
    return (
      <Layout dark>
        <Profile__Header
          data={data.airtable.data}
          svg={<Icon />}
          id={pageContext.airtable_id}
        />

        {data.airtable.data.testimonial_id.length > 0 ? (
          <ProfileYoutube
            icon={<Play />}
            title={
              data.airtable.data.name === "Plug and Play"
                ? "Welcoming Words"
                : "Corporate Testimonial"
            }
            videoId={data.airtable.data.testimonial_id}
            speakers={data.airtable.data.testimonial_speaker}
            company={data.airtable.data.name}
            css={[css`
              margin-bottom: 0;
              padding-bottom: 5em;
            `]}
          />
        ) : (
          ""
        )}

        <section
          css={css`
            background: ${color.main_light};
            padding-bottom: 5em;
          `}
        >
          {champions.length !== 0 && champions !== undefined ? (
            <div css={css`
              margin-bottom: 3em;
            `}>
              <TitleHeader
                title={
                  data.airtable.data.name === "Plug and Play"
                    ? `EXPO Management by ` + data.airtable.data.name
                    : `Champions of ` + data.airtable.data.name
                }
                icon={<Star />}
                css={css`
                  padding-top: 0;
                  padding-bottom: 1em;
                `}
              />
              <ProfileUsers
                data={champions}
                css={css`
                  padding-top: 0;
                  padding-bottom: 0;
                `}
              />
            </div>
          ) : (
            ""
          )}

          {executives.length !== 0 && executives !== undefined ? (
            <div css={css`
              margin-bottom: 3em;
            `}>
              <TitleHeader
                title={`Executives of ` + data.airtable.data.name}
                icon={<Award />}
                css={css`
                  padding-top: 0;
                  padding-bottom: 1em;
                `}
              />
              <ProfileUsers
                data={executives}
                css={css`
                  padding-top: 0;
                  padding-bottom: 0;
                `}
              />
            </div>
          ) : (
            ""
          )}

          {attendee.length !== 0 && attendee !== undefined ? (
            <div css={css`
              margin-bottom: 5em;
            `}>
              <TitleHeader
                title={`All Attendees of ` + data.airtable.data.name}
                icon={<People />}
                subtitle={`<p>To take a look at all attendees of ${data.airtable.data.name} please check out the following link which redirects you to the companies dedicated list of participating employees.`}
                transparent
                css={css`
                  padding-top: 0;
                  padding-bottom: 1em;
                `}
              />
              <div css={wrapper}>
                <ArrowLink
                  text={data.airtable.data.name + ` attendee summary`}
                  url={`/people/` + safe_url}
                  css={css`
                    color: ${color.main_dark};
                    margin-top: 0;
                    margin-bottom: 2em;
                    svg {
                      stroke: ${color.main_dark};
                    }
                  `}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {data.airtable.data.rearview &&
          data.airtable.data.rearview.length > 0 &&
          ifRearview(data.airtable.data.rearview) ? (
            <div css={[wrapper, css`
              margin-bottom: 5em;
            `]}>
              <TitleHeader
                title={`Rearview clips by ` + data.airtable.data.name}
                inline
                icon={<Video />}
                css={css`
                  padding-top: 0;
                  padding-bottom: 1em;
                `}
              />
              <RearviewInline data={data.airtable.data.rearview} />
            </div>
          ) : (
            ""
          )}
        </section>
        <PressFooter theme={color.main_light} />
      </Layout>
    )
}


export const query = graphql`
         query Partner($airtable_id: String) {
           airtable(id: { eq: $airtable_id }) {
             data {
               name
               url
               program
               active
               type
               hq_country
               hq_city
               start_date(formatString: "MMMM DD, YYYY")
               twitter
               linkedin
               instagram
               description {
                 childMarkdownRemark {
                   html
                 }
               }
               users {
                 id
                 data {
                   name
                   group
                   include
                   consent
                   surename
                   profession
                   image {
                     localFiles {
                       childImageSharp {
                         fluid {
                           ...GatsbyImageSharpFluid_withWebp_tracedSVG
                         }
                       }
                     }
                   }
                 }
               }
               rearview {
                 recordId
                 data {
                   publish
                   sort
                   rearview_tags
                   rearview_thumbnail {
                     localFiles {
                       childImageSharp {
                         fluid {
                           ...GatsbyImageSharpFluid_withWebp_tracedSVG
                         }
                       }
                     }
                   }
                   rearview_description {
                     childMarkdownRemark {
                       excerpt(format: PLAIN, pruneLength: 240)
                     }
                   }
                   rearview_release
                   rearview_title
                   rearview_partners {
                     data {
                       name
                     }
                   }
                   rearview_startups {
                     data {
                       startup
                     }
                   }
                   rearview_agenda {
                     data {
                       agenda_start_time
                     }
                   }
                 }
               }
               testimonial_id
               testimonial_speaker {
                 id
                 data {
                   name
                   profession
                 }
               }
             }
           }
         }
       `

export default PartnerTemplate;