import React from 'react';
import PeopleCard from '../main/expcard-people';
import { ExpCardList, ExpCardListItem } from "./expcard"

const ProfileUsers = ({ data, className }) => {
  
  const sortbysurename = (a, b) => {
    if (a.data.surename < b.data.surename) {
      return -1
    }
    if (a.data.surename > b.data.surename) {
      return 1
    }
    return 0
  }

  data.sort( sortbysurename );

  return (
    <ExpCardList className={className}>
      {data.map((user, index) => (
        <PeopleCard data={user} key={index} />
      ))}

      <ExpCardListItem placeholder_item></ExpCardListItem>
      <ExpCardListItem placeholder_item></ExpCardListItem>
      <ExpCardListItem placeholder_item></ExpCardListItem>
      <ExpCardListItem placeholder_item></ExpCardListItem>
      <ExpCardListItem placeholder_item></ExpCardListItem>
    </ExpCardList>
  )
}

export default ProfileUsers;